import kds from './kds'

export default {
  kds,
  order: 'Order',
  orders: 'Ordrar',
  orderStatus: 'Orderstatus',
  printTicket: 'Skriv ut bong',
  email: 'E-post',
  refund: 'Återbetalning',
  receipt: 'Kvittot',
  articles: 'Artiklar',
  information: 'Information',
  deliverFullOrder: 'Levererar hela ordern',
  unDeliverFullOrder: 'Avleverera hela ordern',
  emptyRightNow: 'Här va det tomt just nu.',
  time: 'Tid',
  sortTime: 'Time',
  sortName: 'Name',
  delivery: 'Leverans',
  total: 'Totalt',
  orderCode: 'Order kod',
  qty: 'Ant.',
  of: 'av',
  undefined: 'Odefinierad',
  notDelivered: 'Inte levererad',
  delivered: 'Levererad',
  confirm: {
    one: 'BEKRÄFTA ORDER',
    many: 'BEKRÄFTA ALLA NYA ORDRAR'
  },
  status: {
    started: 'Startad',
    cancelled: 'Avbruten',
    success: 'Slutförd',
    created: 'Skapad',
    waiting: 'Väntar på dricks'
  },
  menu: {
    all: 'Allt',
    recent: 'Senaste',
    done: 'Färdiga',
    filter: 'Filtrera',
    sound: 'Ljud',
    off: 'av',
    on: 'på',
    pos: 'POS',
    kiosk: 'Kiosk',
    online: 'Online',
    origin: 'Ursprung',
    preparation: 'Tillagning',
    grid: 'Rutnät',
    layoutKds: 'KDS',
    list: 'Lista',
    listMini: 'Minilista',
    layout: 'Utseende',
    kdsOrders: 'Visa ordrar som standard',
    ubereats: 'UberEats',
    foodora: 'Foodora',
    wolt: 'Wolt'
  },
  details: {
    dateCreated: 'Datum skapad',
    dateModified: 'Datum modifierad',
    paymentMethod: 'Betalsätt',
    pspId: 'PSP Trans.Id',
    orderCode: 'Order kod',
    currencyRate: 'Valutakurs',
    currencyCode: 'Valutakod',
    paymentStatus: 'Betalnings status',
    deliveryStatus: 'Leveransstatus',
    note: 'Notering',
    amount: 'Summa',
    orderNumber: 'Order nummer',
    delivery: {
      nothing: 'Inget levererat',
      partially: 'Delvis levererad',
      done: 'Färdig, Allt levererat'
    }
  },
  manageDelivery: {
    title: 'Hantera leveransorder',
    titlePreOrder: 'Hantera förbeställd leverans',
    titlePending: 'Hantera väntande leverans',
    titleAccepted: 'Hantera accepterad leverans',
    buttonManage: 'Hantera',
    buttonReady: 'Redo för upphämtning',
    accept: 'Bekräfta',
    deny: 'Avböj',
    confirm: 'Bekräfta',
    deliveryId: 'Delivery ID',
    created: 'Skapad',
    status: 'Status',
    consumer: 'Kund',
    pickupTime: 'Tid för upphämtning',
    pickupTimeMinutes: 'Upphämtning om (minuter)',
    denyReason: 'Anledning',
    denyReasonText: 'Anledning text',
    confirmCancel: 'Verifiera avbryt',
    confirmCancelDescription: 'Är du säker på att avbryta leveransordern?',
    cancelOrderButton: 'Avbryt order',
    cancelReason: 'Anledning',
    cancelReasonText: 'Anledning text',
    estimatedPickupTimeLabel: 'Uppskattad upphämtning:',
    buttonDelivered: 'Levererad',
    orderDeliveredSuccess: 'Ordern markerad som levererad',
    orderReadySuccess: 'Ordern markerad som redo för upphämtning',
    orderAcceptedSuccess: 'Ordern accepterad',
    orderDeniedSuccess: 'Ordern avböjd',
    preOrderConfirmed: 'Förbeställning bekräftad',
    confirmPreOrder: 'BEKRÄFTA FÖRBESTÄLLNING',
    confirmOrder: 'BEKRÄFTA ORDER',
    acceptButton: 'ACCEPTERA',
    denyButton: 'AVBÖJ',
    cancelOrder: 'AVBRYT ORDER',
    autoAccept: 'Auto-acceptera leveransordrar',
    minutes: 'minuter',
    errors: {
      default: 'Det gick inte att uppdatera leveransorderstatus',
      badRequest: 'Ogiltig förfrågan',
      orderNotFound: 'Ordern hittades inte',
      invalidOrder: 'Ogiltig orderinformation',
      apiMethodNotFound: 'Systemfel: Det går inte att behandla leveransordern'
    },
    success: {
      accepted: 'Ordern accepterad',
      denied: 'Ordern avböjd',
      preOrderConfirmed: 'Förbeställning bekräftad'
    },
    notifications: {
      orderAccepted: 'Order {id} accepterad',
      orderDenied: 'Order {id} avböjd',
      preOrderConfirmed: 'Förbeställning {id} bekräftad'
    },
    orderStatus: {
      pending: 'Väntar',
      preparing: 'Förbereder',
      readyForPickup: 'Redo för upphämtning',
      completed: 'Slutförd',
      cancelled: 'Avbruten',
      failed: 'Misslyckad',
      rejected: 'Avvisad',
      refunded: 'Återbetalad',
      unknown: 'Okänd',
      preOrderConfirmed: 'Förbeställning bekräftad',
      preOrderPending: 'Förbeställning väntar'
    }
  },
  origin: {
    errors: {
      fetchFailed: 'Det gick inte att hämta aktuella ordrar',
      processingFailed: 'Det gick inte att bearbeta orderdata'
    }
  }
}
